.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  box-shadow: 24px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 36px;
  padding-bottom: 36px;
  border-radius: 16px;
}

.buttonBox {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: 8px;
}
.buttonStyles {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
