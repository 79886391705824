.sellerCard {
  transition: all 0.2s ease-in;
}

.sellerCard:hover {
  transform: translateY(-5px);
}

.sellerCardGradient {
  padding: 1px;
}

.sellerCard:hover .sellerCardGradient {
  background: linear-gradient(to right, #ad18c7, #11c4e3);
}

.auctionCardButton {
  opacity: 0;
  transition: all 0.2s ease-in;
}

.sellerCard:hover .auctionCardButton {
  opacity: 1;
  transition: all 0.2s ease-in;
}

.auctionCardButtonBox {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.gradientButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
