/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: visible;
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ju367vw {
  border: 0px;
}