.nftCard {
  z-index: 600;
  transition: all 0.2s ease-in;
}

.nftCard:hover {
  transform: translateY(-5px);
}

.gradientBorderBox {
  padding: 1px;
  border-radius: 20px;
}

.nftCard:hover .gradientBorderBox {
}

.cardBody {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  z-index: 10;
  cursor: pointer;
}

.imageStyle {
  width: 100%;
  aspect-ratio: 1;
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px; */
  border-radius: 20px;
}

.nftCardBody {
  /* margin-top: 24px; */
  padding-bottom: 1rem;
}

.nftCardInfo {
  display: flex;
  justify-content: space-between;
}

.nftCardCreator {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nftCardOwner {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dividerStyle {
  background-color: #8e8e8e;
  margin: 5px 0;
}

.nftCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
